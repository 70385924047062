import * as codeType from "constants/generateCodeTypes";

// AZ Elementar partner
export const AZE_PARTNER = {
  urlId: "aze",
  partnerCode: "aze",
  generateStepType: codeType.POLICY_NUMBER_AGE,
  helplineEmail: "gesundheitsversicherung@allianz.at",
  helplinePhoneNumber: "+43 5 9009 588",
  policyNumberRegex: /^(([aA](40|67))|([lL]95))[0-9]{7}$/,
  newdevUrl: "https://dev.personal-assistant.aze.access-to-care-medi24.com/",
  newprodUrl: "https://www.personal-assistant.aze.medi24.com/",
};

export const APKV_OPEN_PARTNER = {
  urlId: "Aq4cVt",
  partnerCode: "apkv-open",
  newdevUrl: "https://dev.personal-assistant.open.access-to-care-medi24.com/",
  newprodUrl: "https://www.personal-assistant.open.medi24.com/",
};

export const APKV_PARTNER = {
  urlId: "apkv",
  partnerCode: "apkv",
  newdevUrl: "https://dev.personal-assistant.apkv.access-to-care-medi24.com/",
  newprodUrl: "https://www.personal-assistant.apkv.medi24.com/",
};

export const TG_GERMANY_PARTNER = {
  urlId: "tg-germany",
  partnerCode: "tg-germany",
  newdevUrl: "https://dev.personal-assistant.de.access-to-care-medi24.com/",
  newprodUrl: "https://www.personal-assistant.de.medi24.com/",
};
