import * as codeType from "constants/generateCodeTypes";
import enInherited from "./translations/en.inherit.json";
import enOverrides from "./translations/en.json";

// inherit-partner.sh emma2 mc-hsbc-sg
// inherit-partner.sh mc-core-sg subpartner mc-hsbc-sg 22 Singapore digitalhealthxtra hsbc-sg allianz mydoc MCHSBCSG en

// DEVTEST Test "MCHSBCSG" with BIN "7070707070"
const config = {
  general: {
    partnerOverride: "DIGITALHEALTHXTRA_HSBC_SG_22",
    partnerGroup: "hsbc-mastercard",
    partnerCountry: "Singapore",
    partnerCode: "mydoc",
    subPartnerCode: "MCHSBCSG",
    generalEmail: "help.mastercard@medi24.com",
    privacyEmail: "help.mastercard@medi24.com",
    title: "",
  },
  features: {
    showEmma2: true,
    DHA: true,
    MyDoc: true,
    generateStepType: codeType.BIN_ONLY,
    helplineEmail: null,
    noMedicalHotline: true,
    showLegalInfo: true,
    showInfermedicaLogo: true,
  },
  footer: {
    address: {
      city: "3006 Bern",
      country: "ch",
      name: "Medi24 AG",
      street: "Bolligenstrasse 54",
    },
    legal: {
      commercialRegister: "CHE-101.216.589",
      securityNumber: "ZSR 0388.0.02",
    },
  }, // footer

  languages: "en".split(" "),
  messages: {
    en: { ...enInherited, ...enOverrides },
  },
};

export default config;
